import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "../containers/Home";
import About from "../containers/About";
// import Service from "../containers/Services";
import Contact from "../containers/Contact";
import ScrollToTop from "./ScrollToTop";
import indusClean from "../containers/Services/indusClean";
import commercialClean from "../containers/Services/commercialClean";
import residentialClean from "../containers/Services/residentialClean";

export default function Routers() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/residentialClean" component={residentialClean} />
        <Route path="/commercialClean" component={commercialClean} />
        <Route path="/indusClean" component={indusClean} />
        <Route path="/contact" component={Contact} />
        <Route path="/about" component={About} />
        <Route path="/" component={Home} />
        {/* <Route path="/services" component={Service} /> */}
      </Switch>
    </Router>
  );
}
