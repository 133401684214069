import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Button, Container } from 'react-bootstrap';

const Form = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_gew0f2k',
        'template_jvl9k48',
        form.current,
        'user_1ruqXmNFSTuuGdPaCDazE'
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          alert('Submitted successfully.');
        },
        (error) => {
          console.log(error.text);
          alert('Something wrong Happened');
        }
      );
  };

  return (
    <>
      <div className='contact__form--wrapper'>
        <Container>
          <h5>Get a quote</h5>
          <form action='' ref={form} onSubmit={sendEmail}>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='name'>
                  <label for='name'>Name * </label>
                  <input type='text' name='name' id='' required />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='email'>
                  <label for='email'>Email * </label>
                  <input type='email' name='email' id='' required />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='phone'>
                  <label for='phone'>Phone * </label>
                  <input type='text' name='phone' id='' required />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='address'>
                  <label for='address'>Address *</label>
                  <input type='text' name='address' id='' required />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='service'>
                  <label for='service'>Services </label>
                  <select id='service' name='service' required>
                    <option value=''></option>
                    <option value='Industrail Cleaning'>
                      {' '}
                      Industrial Cleaning{' '}
                    </option>
                    <option value='Commercial Cleaning'>
                      Commercial Cleaning
                    </option>
                    <option value='Residental Cleaning'>
                      Residential Cleaning
                    </option>
                  </select>
                </div>
              </div>
              <div className='col-sm-6'></div>
            </div>
            <div className='row'>
              <div className='col'>
                <div className='messsage'>
                  <label for='message'>Message</label>
                  <textarea
                    name='msg'
                    id=''
                    cols='50'
                    rows='4'
                    required
                  ></textarea>
                </div>
              </div>
            </div>
            <div className='contact__form--button'>
              <Button type='submit'> submit </Button>
            </div>
          </form>
        </Container>
      </div>
    </>
  );
};

export default Form;
