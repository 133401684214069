import React from "react";
import Banner from "../../components/Banner";
import Client from "../../components/Clients";
import Contact from "../../components/Contact";
import Service from "../../components/Service";
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import Testimonial from "../../components/Testimonials";
import Slide from "react-reveal/Slide";

const Home = () => {
  return (
    <>
      <Header />
      <Banner />
      <Service />

      <Slide left>
        <Client />
      </Slide>

      <Testimonial />

      <Contact />

      <Footer />
    </>
  );
};

export default Home;
