import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

//react-bootstrap
import { Container, Row, Col } from "react-bootstrap";
import { Nav, Navbar, Dropdown } from "react-bootstrap";

// react-icons
import { ImPhone } from "react-icons/im";
import { MdLocationOn } from "react-icons/md";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

//images
import logo from "../../../assets/images/home-page/logo.png";

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <>
      <header className="header">
        <div className="header__top">
          <Container>
            <Row>
              <Col>
                <div className="header__top--details">
                  <div className="mr76">
                    <span className="info location">
                      <MdLocationOn className="mr" />8 Leahy Place, Gordon, ACT
                      2906
                    </span>
                  </div>
                  <div>
                    <span className="info contact">
                      <ImPhone className="mr" />
                      0426994806
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="header__top--social">
                  <FaFacebookF className="social" />
                  <FaLinkedinIn className="social" />
                  <FaTwitter className="social" />
                  <FaYoutube className="social" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="header__bottom">
          <Navbar collapseOnSelect expand="lg" className="navheader">
            <Container>
              <Navbar.Brand as={Link} to="/">
                <div className="navheader__logo">
                  <img
                    src={logo}
                    alt="logo"
                    className="logo"
                    width="82px"
                    height="72px"
                  />
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto"></Nav>
                <Nav>
                  <NavLink
                    exact
                    to="/"
                    activeClassName="active"
                    className="nav-item"
                  >
                    Home
                  </NavLink>

                  {/* <NavDropdown as={Link} activeClassName="active" to="/services" className="active nav-items nav-items--services" title="Services" id="collasible-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/services">INDUSTRIAL CLEANING </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/services">RESIDENTIAL CLEANING</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/services">COMMERCIAL CLEANING </NavDropdown.Item>
                </NavDropdown> */}

                  <Dropdown
                    className="nav-items"
                    onMouseLeave={() => setShowDropdown(false)}
                    onMouseOver={() => setShowDropdown(true)}
                  >
                    <Dropdown.Toggle className="main-style" id="dropdown-basic">
                      {" "}
                      Services{" "}
                    </Dropdown.Toggle>
                    <Dropdown.Menu show={showDropdown}>
                      <Dropdown.Item
                        activeClassName="active"
                        as={Link}
                        to="/indusClean"
                      >
                        INDUSTRIAL CLEANING{" "}
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        activeClassName="active"
                        as={Link}
                        to="/residentialClean"
                      >
                        RESIDENTIAL CLEANING{" "}
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        activeClassName="active"
                        as={Link}
                        to="/commercialClean"
                      >
                        COMMERCIAL CLEANING{" "}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <NavLink
                    activeClassName="active"
                    to="/about"
                    className="nav-items"
                  >
                    About
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/contact"
                    className="nav-items"
                  >
                    Contact
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </header>
    </>
  );
};

export default Header;
