import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/home-page/logo.png";
import {
  FaAngleRight,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <section className="footer" id="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="footer__wrapper">
                <div className="footer__wrapper--img">
                  <Link to="/">
                    <img src={logo} alt="" className="img-fluid" />
                  </Link>
                </div>
                <p>
                  B and S Cleaning company is a professional cleaning company
                  which is operating around the Australian Capital Territory and
                  mainly in Canberra region.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer__wrapper">
                <h3>Links</h3>

                <div className="footer__wrapper--list">
                  <span className="footer__wrapper--list-items">
                    <FaAngleRight className="icon" />
                    <Link to="/">Home</Link>
                  </span>{" "}
                  <br />
                  {/* <span className="footer__wrapper--list-items">
                                <FaAngleRight  className="icon" />
                                    <Link to="/services">Services</Link>
                            </span> <br /> */}
                  <span className="footer__wrapper--list-items">
                    <FaAngleRight className="icon" />
                    <Link to="/about">About</Link>
                  </span>{" "}
                  <br />
                  <span className="footer__wrapper--list-items">
                    <FaAngleRight className="icon" />
                    <Link to="/contact">Contact</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer__wrapper">
                <h3>Services</h3>
                <div className="footer__wrapper--list">
                  <span className="footer__wrapper--list-items">
                    <FaAngleRight className="icon" />
                    <Link to="/indusClean">Industrial Cleaning</Link>
                  </span>{" "}
                  <br />
                  <span className="footer__wrapper--list-items">
                    <FaAngleRight className="icon" />
                    <Link to="/commercialClean"> Commercial Cleaning</Link>
                  </span>{" "}
                  <br />
                  <span className="footer__wrapper--list-items">
                    <FaAngleRight className="icon" />
                    <Link to="/residentialClean">Residential Cleaning</Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer__wrapper">
                <h3>Contact</h3>
                <div className="footer__wrapper--list">
                  <span className="footer__wrapper--list-items">
                    <FaMapMarkerAlt className="mr-13" /> 8 Leahy Place, Gordon,{" "}
                    <br /> ACT 2906
                  </span>{" "}
                  <br />
                  <span className="footer__wrapper--list-items">
                    <FaPhoneAlt className="mr-13" /> 0426994806 <br />{" "}
                    0451659680
                  </span>{" "}
                  <br />
                  <span className="footer__wrapper--list-items">
                    <FaEnvelope className="mr-13" />{" "}
                    bsindustrialcleaningservice@gmail.com
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="footer__bottom">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="footer__bottom--wrapper">
                  <span>Copyright @ B&S Cleaning services . 2020 </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Footer;
