import React from 'react'
import Contact from '../../components/Contact';
import Footer from '../../components/layout/Footer';
import Header from '../../components/layout/Header'
import AboutBanner from '../../components/About';
import Gallery from '../../components/gallery';
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect

const About = () => {
  return (
    <>
    <Header />
    <AboutBanner />
    <Gallery />

    <Zoom>
      <Contact />
    </Zoom>
    <Footer />
    </>
  )
}

export default About;