import React from "react";
import Cards from "../../components/cards";
import Clean from "../../components/Clean";
import Contact from "../../components/Contact";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import Zoom from "react-reveal/Zoom";
import homeCleaning from "../../assets/images/home-page/homeCleaning.jpg";

const residentialClean = () => {
  return (
    <>
      <Header />
      <Clean
        title="RESIDENTIAL CLEANING"
        content="We offer various services regarding the residential cleaning which 
                includes end of lease cleaning, Strata cleaning and home cleaning. "
        imageSource={homeCleaning}
      />
      <Cards
        title1="HOUSE CLEANING"
        content1="People who want to have more time on their work or
        family can get more benefits from these services
        which will provide the house cleaning services.
        Our professional cleaners will provide this
        service to you so that you can spend your time
        with work and family."
        title2="STRATA CLEANING"
        content2="We offer various services to the Strata Cleaning. If you are looking for the cleaning services for your apartments or buildings, you can contact us with the services you required. We will make the flexible services as per your requirements and our experts will do scheduled cleaning on your properties to make the environment clean."
        title3="END OF LEASE CLEANING"
        content3="Similarly, you can have end of lease cleaning when you are moving houses and can have spare time in decorating and focusing on the new accommodation. Our experts will help to clean your place so that you can handover the lease without any problems. We believe you all deserves to have full bond refund with no payment need to make and the end of lease."
      />
      <Zoom>
        <Contact />
      </Zoom>
      <Footer />
    </>
  );
};

export default residentialClean;
