import React from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import Fade from "react-reveal/Fade";

const Clean = ({ title, content, imageSource, list, secondpara }) => {
  return (
    <>
      <article className="indus-cleaning">
        <Container>
          <Row>
            <Col md={6}>
              <Fade left>
                <Container>
                  <section className="heading">
                    <div className="heading-box">
                      <div className="title">
                        <span>
                          WE CLEAN INDUSTRIES
                          <span className="line">.</span>
                        </span>
                        <h2>{title}</h2>
                      </div>
                    </div>
                  </section>
                  <div className="contents">
                    <p>{content} </p>
                    <b>{list}</b>
                    <p> {secondpara} </p>
                    <Button>Request a quote</Button>
                  </div>
                </Container>
              </Fade>
            </Col>

            <Col md={6}>
              <Fade right>
                <Container>
                  <div className="img-wrapper">
                    <img src={imageSource} alt="cleaning banner" width="100%" />
                  </div>
                </Container>
              </Fade>
            </Col>
          </Row>
        </Container>
      </article>
    </>
  );
};

export default Clean;
