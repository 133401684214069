import React from "react";
import { Link } from "react-router-dom";
import Heading from "../headings";
import { BsArrowRight } from "react-icons/bs";
import ellipse from "../../assets/images/home-page/Ellipse.png";
import indus from "../../assets/images/home-page/indus-clean.jpg";
import commer from "../../assets/images/home-page/commercialCleaning.jpg";
import resi from "../../assets/images/home-page/homeCleaning.jpg";
import resiCircle from "../../assets/images/home-page/house.png";
import indusCircle from "../../assets/images/home-page/indus.png";
import commerCircle from "../../assets/images/home-page/hands.png";
import Fade from "react-reveal/Fade";

const Service = () => {
  return (
    <>
      <section className="services">
        <Heading />

        <article className="services-cards">
          <div className="services__bottom">
            <div className="container">
              <div className="row">
                <Fade bottom>
                  <div className="col-md-4">
                    <section className="services__bottom--border">
                      <div className="services__bottom-card__img">
                        <img
                          src={indus}
                          alt=""
                          className="services__bottom-card-img"
                        />
                      </div>
                      <div className="image-overlay">
                        <img src={ellipse} alt="elipse" className="ellipse" />
                        <img
                          src={indusCircle}
                          alt=""
                          className="services__bottom--img"
                        />
                      </div>

                      <div className="services__bottom--wrapper">
                        <div className="services__bottom-card__content">
                          <h3>Industrial Cleaning</h3>
                          <p>
                            We provide range of services for your industry when
                            required. It could be scheduled Cleaning or
                            emergency cleaning situation. Our trained and
                            skilled cleaners will be in your spot to make your
                            environment clean and tidy. Our Industrial Cleaning
                            services includes Standard Scheduled Cleaning,
                            Window Cleaning, Detailed Cleaning, Flooring and 24-
                            hours Emergency Cleaning.
                          </p>
                        </div>

                        <div className="view__more">
                          <span>
                            <Link to="/indusClean">View More</Link>
                          </span>
                          <BsArrowRight />
                        </div>
                      </div>
                    </section>
                  </div>
                </Fade>
                <div className="col-md-4">
                  <section className="services__bottom--border">
                    <div className="services__bottom-card__img">
                      <img
                        src={commer}
                        alt=""
                        className="services__bottom-card-img"
                      />
                    </div>
                    <div className="image-overlay">
                      <img src={ellipse} alt="elipse" className="ellipse" />
                      <img
                        src={commerCircle}
                        alt=""
                        className="services__bottom--img"
                      />
                    </div>

                    <div className="services__bottom--wrapper">
                      <div className="services__bottom-card__content">
                        <h3>Commercial Cleaning</h3>
                        <p>
                          We offer various cleaning services to help commercial
                          place to keep clean and tidy environment. We focus on
                          making your commercial environment a place to be work
                          for your employees and customers. Commercial Cleaning
                          includes Office Cleaning, Carpet Cleaning, Windows
                          Cleaning, Carpark Cleaning.
                        </p>
                      </div>

                      <div className="view__more">
                        <span>
                          <Link to="/commercialClean">View More</Link>
                        </span>
                        <BsArrowRight />
                      </div>
                    </div>
                  </section>
                </div>
                <Fade bottom>
                  <div className="col-md-4">
                    <section className="services__bottom--border">
                      <div className="services__bottom-card__img">
                        <img
                          src={resi}
                          alt=""
                          className="services__bottom-card-img"
                        />
                      </div>
                      <div className="image-overlay">
                        <img src={ellipse} alt="elipse" className="ellipse" />
                        <img
                          src={resiCircle}
                          alt=""
                          className="services__bottom--img"
                        />
                      </div>

                      <div className="services__bottom--wrapper">
                        <div className="services__bottom-card__content">
                          <h3>Residential Cleaning</h3>
                          <p>
                            We offer various services regarding the residential
                            cleaning which includes end of lease cleaning,
                            Strata cleaning and home cleaning.
                          </p>
                        </div>

                        <div className="view__more">
                          <span>
                            <Link to="/residentialClean">View More</Link>
                          </span>
                          <BsArrowRight />
                        </div>
                      </div>
                    </section>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </article>
      </section>
    </>
  );
};

export default Service;
