import React from "react";
import { CardGroup, Card, Container, Row } from "react-bootstrap";
import Carpet from "../../assets/images/service-page/carpet.png";
import Regular from "../../assets/images/service-page/clean2.png";
import EOL from "../../assets/images/service-page/clean.png";
import Fade from "react-reveal/Fade";

const Cards = (props) => {
  return (
    <>
      <section className="cards">
        <Container>
          <section className="heading">
            <div className="heading-box">
              <div class="title">
                <span>
                  WHAT WE CLEAN
                  <span className="line">.</span>
                </span>
                <h2>CLEANING TYPES</h2>
              </div>
            </div>
          </section>

          <section className="cleaning-types">
            <article className="cleaning-card">
              <Container>
                <Row>
                  <CardGroup>
                    <Card>
                      <Fade bottom>
                        <div className="card-wrapper green">
                          <Card.Img
                            variant="top"
                            src={Regular}
                            className="img-props"
                          />
                          <Card.Body>
                            <Card.Title>{props.title1}</Card.Title>
                            <Card.Body className="p0">
                              {props.content1}
                            </Card.Body>
                          </Card.Body>
                        </div>
                      </Fade>
                    </Card>

                    <Card>
                      <div className="card-wrapper blue">
                        <Card.Img
                          variant="top"
                          src={Carpet}
                          className="img-props"
                        />
                        <Card.Body>
                          <Card.Title>{props.title2}</Card.Title>
                          <Card.Body className="p0">{props.content2}</Card.Body>
                        </Card.Body>
                      </div>
                    </Card>

                    <Card>
                      <Fade bottom>
                        <div className="card-wrapper red">
                          <Card.Img
                            variant="top"
                            src={EOL}
                            className="img-props"
                          />
                          <Card.Body>
                            <Card.Title>{props.title3}</Card.Title>
                            <Card.Body className="p0">
                              {props.content3}
                            </Card.Body>
                          </Card.Body>
                        </div>
                      </Fade>
                    </Card>
                  </CardGroup>
                </Row>
              </Container>
            </article>
          </section>
        </Container>
      </section>
    </>
  );
};

export default Cards;
