import React from "react";
import Cards from "../../components/cards";
import Clean from "../../components/Clean";
import Contact from "../../components/Contact";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import Zoom from "react-reveal/Zoom";
import { Card, CardGroup } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import commercialCleaning from "../../assets/images/home-page/commercialCleaning.jpg";
import Regular from "../../assets/images/service-page/regular.png";
import { Container, Row, Col } from "react-bootstrap";

const commercialClean = () => {
  return (
    <>
      <Header />
      <Clean
        title="COMMERCIAL CLEANING"
        content="We offer various cleaning services to help commercial place to keep clean and tidy environment. We focus on making your commercial environment a place to be work for your employees and customers. Commercial Cleaning includes Office Cleaning, Carpet Cleaning, Windows Cleaning, Carpark Cleaning. "
        imageSource={commercialCleaning}
      />
      <Cards
        title1="OFFICE CLEANING"
        content1="We provide the standard and flexible office cleaning upon your required services. Our experts cleaning will perform the cleaning on standard scheduled time and provide the quality cleaning on your office. It includes, dusting, wiping, vacuuming, moping, garbage cleaning and many more. You can contact us for the quotes."
        title2="CARPET CLEANING"
        content2="If your commercial property needs a carpet cleaning, you can get quotes for the one-time carpet cleaning or the regular carpet cleaning. Our carpet cleaning expert will visit on your property and provides the quotes and time to perform the carpet cleaning. We use the advance technology with the experts to make your carpet clean and provide the 100% satisfaction."
        title3="CARPARK CLEANING"
        content3="If your commercial property needs a carpark cleaning, you can get quotes for the one-time carpark cleaning or the regular carpark cleaning. Our carpark cleaning expert will visit on your property and provides the quotes and time to perform the carpark cleaning. We use the advance technology with the experts to make your carpark clean and provide the 100% satisfaction."
      />
      <Row className="single-card-row">
        <Col md={4}></Col>
        <Col md={4} className="cards single-card">
          <section className="cleaning-types">
            <article className="cleaning-card">
              {/* <Container> */}
              {/* <Row> */}
              {/* <CardGroup> */}
              <Card>
                <Fade bottom>
                  <div className="card-wrapper green">
                    <Card.Img
                      variant="top"
                      src={Regular}
                      className="img-props"
                    />
                    <Card.Body>
                      <Card.Title>WINDOW CLEANING</Card.Title>
                      <Card.Body className="p0">
                        If your commercial property needs a windows cleaning,
                        you can get quotes for the one-time windows cleaning or
                        the regular windows cleaning. Our windows cleaning
                        expert will visit on your property and provides the
                        quotes and time to perform the windows cleaning. We use
                        the advance technology with the experts to make your
                        windows clean and provide the 100% satisfaction.
                      </Card.Body>
                    </Card.Body>
                  </div>
                </Fade>
              </Card>
              {/* </CardGroup> */}
              {/* </Row> */}
              {/* </Container> */}
            </article>
          </section>
        </Col>
        <Col md={4}></Col>
      </Row>

      <Zoom>
        <Contact />
      </Zoom>
      <Footer />
    </>
  );
};

export default commercialClean;
