import React from 'react';
import Cards from '../../components/cards';
// import Cleaning from "../../components/Cleaning";
import Clean from '../../components/Clean';
import Contact from '../../components/Contact';
import Footer from '../../components/layout/Footer';
import Header from '../../components/layout/Header';
import Zoom from 'react-reveal/Zoom'; // Importing Zoom effect
import indusCleaning from '../../assets/images/home-page/indus-clean.jpg';

const indusClean = () => {
  return (
    <>
      <Header />
      <Clean
        title='INDUSTRIAL CLEANING'
        content='We provide range of services for your industry when
                      required. It could be scheduled Cleaning or emergency
                      cleaning situation. Our trained and skilled cleaners will
                      be in your spot to make your environment clean and tidy.
                      Our Industrial Cleaning services includes:'
        list='Standard Scheduled Cleaning, 
        Window Cleaning, 
        Detailed Cleaning, 
        Flooring, 
        24- hours Emergency Cleaning'
        secondpara='At B and S Cleaning, we help to create good and clean
      environment so that ayour process will go smoothly and
      productively. We also adhere to strict codes quality and
      practice, ensuring all work completed complies with
      relevaenvironmental and OHS requirements.'
        imageSource={indusCleaning}
      />
      {/* <Cards /> */}
      <Zoom>
        <Contact />
      </Zoom>
      <Footer />
    </>
  );
};

export default indusClean;
