import React from 'react';
import Map from '../Map';
import Form from '../Form';
import { Col, Container, Row } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPhoneAlt,FaEnvelope } from "react-icons/fa";

const Contactus = () => {
  return (
    <>
    <section className="contact-us">
        <Container>
            <Row>
              <Col md={6}>
                <div className="footer__wrapper--list">
                  <span className="footer__wrapper--list-items">
                    <FaMapMarkerAlt className="mr-13" />  8 Leahy Place, Gordon, <br/> ACT 2906 
                  </span> <br />
                  <span className="footer__wrapper--list-items">
                      <FaPhoneAlt className="mr-13"/> 0426994806 <br /> 0451659680
                  </span> <br />
                  <span className="footer__wrapper--list-items">
                    <FaEnvelope className="mr-13" />  bsindustrialcleaningservice@gmail.com
                  </span> 
                </div>

                  <Map />
                  
              </Col>
              <Col md={6}> 
                    <Form />
              </Col>
            </Row>
        </Container>
    </section>
    </>
  )
}

export default Contactus;