import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import Company from '../../assets/images/about-us/about-banner.png';
import Fade from 'react-reveal/Fade';

const AboutBanner = () => {
    return(
    <>
    <article className="indus-cleaning">
        <Container >
            <Row>
                <Col md={6}>
            <Fade left>
                    <Container>
                        <section className="heading">
                            <div className="heading-box">
                                <div class="title">
                                    <span>WHO WE ARE
                                        <span className="line">.</span>
                                    </span>
                                    <h2>OUR COMPANY</h2>
                                </div>
                            </div>
                        </section>
                        <div className="contents">
                            <p>
                            B and S Cleaning company is a professional cleaning company which is operating around the
                            Australian Capital Territory and mainly in Canberra region. The company was founded on 2020 by
                            Buddhi Tamang and Shiva Shrestha. The initial letter of the founder members remains as the
                            company name. With the trust and beliefs of the existing customers, the company is receiving the high demand of the customers and being a renowned business in the territory. <br />

                            The quality of services, success and appreciation of the customers are the key success of our business. We are currently serving 1000s of the industrial, commercial, and residential cleaning services. We provide as essential training to our cleaners so that we can give best services in the area. We use highly advanced cleaning equipment to provide different facilities in the cleaning services to maintain the Quality of Service. <br />

                            We have verities of the customers who are satisfied with our services who are recommending their friends and families to us. We are glad to be serving them in a long run.
                            </p>
                        </div>
                    </Container>
                </Fade>
                </Col>

                <Col md={6}>
                    <Fade right>
                    <Container>
                        <div className="img-wrapper">
                            <img src={Company} alt="cleaning banner" width="100%" /> 
                        </div>
                    </Container>
                    </Fade>
                </Col>
            
            </Row>
        </Container>
    </article>
    </>
    )
}

export default AboutBanner;