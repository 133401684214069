import React from 'react';
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
// import banner1 from '../../assets/images/home-page/front-img.png';
// import banner from '../../assets/images/home-page/banner-img.png';
import banner from '../../assets/images/home-page/Frame9.png';
import Slide from 'react-reveal/Slide';

const Banner = () => {
    return (
    <>
    <section className="position-relative hero" style={{ height: '553px' }}> 
        <div className="d-flex">
            {/* <img src={banner1} alt="banner" className="img-fluid left"/> */}
            {/* <img src={banner} alt="banner" className="img-fluid right"/> */}
            <img src={banner} alt="banner" width= "100%"/>
        </div>
        
        <Container>
        <Slide left cascade>
        <div className="figcaption">
            <h1>A CLEAN SPACE IS A <br /> CLEAN BUSINESS</h1>
            <p>100% QUALITY <br/>
                Get 10% Off on your first service.</p>
                <Button>Request a quote</Button>
        </div>
        </Slide>
        </Container>
    </section>





















    {/* <section className="banner">   
    <figure className="position-relative banner">
                <div className="img-fluid">
                    <img src={banner1} alt="banner" className="banner-img bimg-left"/>
                    <img src={banner} alt="banner" className="banner-img bimg-right"/>
                </div>
                <Container>
               <figcaption>
               <div>
                   <h1>A CLEAN SPACE IS A <br /> CLEAN BUSINESS</h1>
                   <p>100% QUALITY <br/>
                        Get 10% Off on your first service.</p>
                   <Button> Request a quote</Button>
               </div>
               </figcaption>
        </Container>
    </figure> 
    </section> */}
    </>
    );
}

export default Banner;